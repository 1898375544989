<template>
  <div class="input" :class="{ disabled, withChange, gaps: withGaps }">
    <label
      v-if="label || typeof error === 'string'"
      class="input__label"
      :class="{ required: required && !error, error }"
    >{{ error || label }}</label>
    <div class="input__wrapper" :class="{ error, focus: isFocus }">
      <div class="input__part input__hint-placeholder" v-if="hintPlaceholder">
        <input
          class="input__control input__hint-placeholder"
          type="text"
          disabled
          :placeholder="getHintPlaseholder"
        />
      </div>
      <div class="input__part" v-if="mask">
        <input
          class="input__control"
          type="text"
          v-bind="$attrs"
          v-bind:value="value"
          v-mask="mask"
          :required="required"
          :disabled="disabled"
          :class="{ center, disabled }"
          @input="$emit('input', $event.target.value)"
          @focus="handleFocus"
          @blur="handleBlur"
        />
      </div>
      <template v-else-if="withDate">
        <div
          class="input__part"
          :class="{ autocomplete: !!getSuggestionList, opened }"
        >
          <vue-simple-suggest
            v-if="!!getSuggestionList"
            :class="{ opened }"
            :value="value && value.text"
            @input="$emit('input', {
              text: $event,
              date: value.date,
              disabled,
            })"
            @focus="handleFocus"
            @blur="handleBlur"
            @show-list="() => opened = true"
            @hide-list="() => opened = false"
            value-attribute="value"
            display-attribute="label"
            :list="getSuggestionList"
            :debounce="300"
            :filter-by-query="false"
          >
            <input
              class="input__control"
              :class="{ center, disabled, error: value && value.textError }"
              :value="value && value.text"
              :required="required"
              :disabled="disabled"
              v-bind="$attrs"
              autocomplete="off"
            />
          </vue-simple-suggest>
          <input
            v-else
            class="input__control"
            :class="{ center, disabled, error: value && value.textError }"
            type="text"
            v-bind="$attrs"
            v-bind:value="value && value.text"
            :required="required"
            :disabled="disabled"
            @input="
              $emit('input', { text: $event.target.value, date: value.date, disabled })
            "
            @focus="handleFocus"
            @blur="handleBlur"
          />
        </div>
        <div class="input__part">
          <div class="input__part input__hint-placeholder input__native-date">
            <input
              class="input__control input__hint-placeholder input__native-date"
              type="text"
              disabled
              :placeholder="getHintDatePlaseholder"
            />
          </div>
          <input
            class="input__control input__native-date"
            :class="{ center, disabled, error: value && value.dateError }"
            type="text"
            v-bind="$attrs"
            v-bind:value="value && value.date"
            :required="required"
            :disabled="disabled"
            placeholder
            v-mask="'##.##.####'"
            pattern="[0-9]*"
            inputmode="numeric"
            @input="
              $emit('input', {
                text: value.text,
                date: $event.target.value,
                disabled
              })
            "
            @focus="handleFocus"
            @blur="handleBlur"
          />
        </div>
      </template>
      <div class="input__part" v-else>
        <input
          class="input__control"
          type="text"
          v-bind="$attrs"
          v-bind:value="value"
          :required="required"
          :disabled="disabled"
          :class="{ center, disabled }"
          @input="$emit('input', $event.target.value)"
          @focus="handleFocus"
          @blur="handleBlur"
        />
      </div>
      <GiftBall
        v-if="giftBall && !disabled"
        :value="giftBall"
        class="input__part input__part_gift"
      />
      <div v-if="withChange" class="input__part input__part_change">
        <div class="input__change" @click="handleClickChange">
          <span class="link">Изменить</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
import GiftBall from "@/components/ui/GiftBall.vue";

export default {
  name: "Input",
  components: { VueSimpleSuggest, GiftBall },
  props: {
    error: [String, Boolean],
    label: String,
    required: Boolean,
    disabled: Boolean,
    value: [String, Date, Object],
    giftBall: Number,
    hintPlaceholder: Boolean,
    mask: String,
    center: Boolean,
    withChange: Boolean,
    date: String,
    withDate: Boolean,
    withGaps: Boolean,
    getSuggestionList: Function,
  },
  data: () => ({
    isFocus: false,
    datePlaseholder: "01.01.1900",
    nomask: {
      mask: "*".repeat(255),
      tokens: {
        "*": { pattern: /./ }
      }
    },
    opened: false,
  }),
  methods: {
    handleFocus(event) {
      this.isFocus = true;
      setTimeout(() => {
        event.target.selectionStart = event.target.selectionEnd = 10000;
      }, 0);
      this.$emit("onFocus", event);
    },
    handleBlur(event) {
      this.isFocus = false;
      this.$emit("onBlur", event);
    },
    handleClickChange() {
      this.$emit("onClickChange");
    },
  },
  computed: {
    getHintPlaseholder() {
      const { placeholder } = this.$attrs;
      const value = this.value || "";
      return value + placeholder.slice(value.length);
    },
    getHintDatePlaseholder() {
      const value = (this.value && this.value.date) || "";
      return value + this.datePlaseholder.slice(value.length);
    }
  }
};
</script>

<style lang="scss">
.input {
  display: flex;
  flex-direction: column;
  position: relative;

  &__wrapper {
    @extend %controlsFontSize;
    border-radius: 15px;
    background-color: $color-input-bg;
    color: $color-input-text;
    width: 100%;
    border: solid thin transparent;
    transition: 0.15s ease-in-out;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-md) {
      border-radius: 10px;
    }

    &.focus {
      border-color: $color-input-border-active;
    }

    &.error {
      border-color: $color-error;
    }
  }

  &__date {
    width: 100%;
    min-width: 160px;
  }

  &__label {
    line-height: 1.35;
    color: $color-text-pseudo;
    margin-bottom: 4px;
    margin-top: 6px;
    display: inline-block;

    font-size: 14px;
    font-weight: 500;

    @media (min-width: $screen-md) {
      font-size: 16px;
      font-weight: normal;
    }

    &.error,
    &.required::after {
      content: "*";
    }

    &.error {
      color: $color-error;
    }
  }

  &__part {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1 1 auto;

    &_change,
    &_gift {
      height: auto;
      padding-right: 20px;
    }

    &_gift {
      flex: initial;
    }

    &:not(:first-child) .input__native-date {
      padding-left: 0;
      padding-right: 10px;

      max-width: 130px;

      @media (min-width: $screen-md) {
        max-width: none;
        min-width: 150px;
      }
    }
  }

  &_valid {
    box-shadow: 0 0 4px 0 $color-accent;
  }

  &_invalid {
    box-shadow: 0 0 4px 0 $color-red;
  }

  &__control {
    background: transparent;
    border: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: inherit;
    padding: 16px 20px 13px 20px;
    box-sizing: border-box;
    position: relative;
    border-radius: 15px;
    height: 51px;
    font-variant-numeric: tabular-nums;

    @media (min-width: $screen-md) {
      padding: 22px 20px 17px 20px;
      border-radius: 10px;
      height: 68px;
    }

    &:focus {
      outline: 0;
    }

    &::placeholder {
      font: inherit !important;
    }

    &.center {
      text-align: center;
    }

    &.disabled {
      color: #9299a2;
      pointer-events: none;
      -webkit-text-fill-color: #9299a2;
      opacity: 1;
    }
  }

  &__hint {
    position: absolute;
    right: 0;
    bottom: calc(100% + 8px);
    font-size: 12px;
    color: #72747a;
    font-weight: 500;
    background-color: #ffffff;
    border-radius: 11px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.21);
    padding: 4px 11px;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: -5px;
      right: 16px;
      height: 8px;
      width: 8px;
      background-color: #ffffff;
      transform: rotate(45deg);
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.21);
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -5px;
      right: 15px;
      height: 10px;
      width: 10px;
      background-color: #ffffff;
      transform: rotate(45deg);
    }
  }

  &__icon {
    margin-left: 4px;
    line-height: 1;
  }

  &__hint-placeholder {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    font-variant-numeric: tabular-nums;
  }

  &__change {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    box-sizing: border-box;

    font-size: 12px;
    font-weight: 500;

    cursor: pointer;
  }

  &.disabled {
    .input__wrapper {
      background-color: #f1f1f1;
    }
    .input__gift {
      filter: grayscale(1);
    }
  }

  &.gaps {
    .input__wrapper {
      background-color: transparent;
      border: none;
      gap: 21px;

      > .input__part {
        background-color: $color-input-bg;
        border-radius: 15px;

        &.opened {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .input__control {
          border: solid thin transparent;

          &:focus {
            border-color: $color-input-border-active;
          }

          &.error {
            border-color: $color-error;
          }
        }

        .input__native-date {
          padding: 16px 20px 13px 20px;
          max-width: none;
        }

        @media (min-width: $screen-md) {
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
