<template>
  <div class="qrcode" v-if="url">
    <qrcode-vue :value="url" :size="size" level="L" class="qrcode_inner" />
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "QRCode",
  props: {
    url: String
  },
  data() {
    return {
      size: 140
    };
  },
  components: {
    QrcodeVue
  }
};
</script>

<style scoped lang="scss">
.qrcode {
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.17);
  background: #ffffff;
  display: inline-block;
}

canvas,
.qrcode_inner {
  display: flex;
}
</style>
