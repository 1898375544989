<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="15"
    viewBox="0 0 39 15"
  >
    <path
      fill="#A9AEB5"
      fill-rule="nonzero"
      d="M7.102.636a.7.7 0 0 1 1 0c.27.27.27.72 0 .99l-5.07 5.069h34.73a.704.704 0 1 1 0 1.41H3.031l5.07 5.06c.27.28.27.73 0 1a.7.7 0 0 1-1 0l-6.27-6.27a.705.705 0 0 1 0-.99l6.27-6.27z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconLeftArrow"
};
</script>
