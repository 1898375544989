<template>
  <button
    v-if="!href"
    class="button"
    :class="{
      button_disabled: disabled,
      button_telegram: isTelegram,
      button_phone: isPhone,
      button_sms: isSMS,
      button_whatsapp: isWhatsapp,
    }"
    v-bind="$attrs"
    @click="$emit('click')"
  >
    <div class="content">
      <slot />
      <IconTelegram v-if="isTelegram" />
      <IconSMS v-if="isSMS" />
      <IconPhone v-if="isPhone" />
    </div>
  </button>

  <a
    v-else
    class="button"
    :class="{
      button_disabled: disabled,
      button_telegram: isTelegram,
      button_phone: isPhone,
      button_sms: isSMS,
      button_whatsapp: isWhatsapp,
    }"
    :href="href"
  >
    <div class="content">
      <slot />
      <IconTelegram v-if="isTelegram" />
      <IconSMS v-if="isSMS" />
      <IconPhone v-if="isPhone" />
      <IconWhatsapp v-if="isWhatsapp" />
    </div>
  </a>
</template>

<script>
import IconTelegram from "@/icons/IconTelegram.vue";
import IconSMS from "@/icons/IconSMS.vue";
import IconPhone from "@/icons/IconPhone.vue";
import IconWhatsapp from "@/icons/IconWhatsapp.vue";

export default {
  name: "Button",

  components: {
    IconTelegram,
    IconSMS,
    IconPhone,
    IconWhatsapp,
  },

  props: {
    disabled: Boolean,
    isTelegram: Boolean,
    isSMS: Boolean,
    isPhone: Boolean,
    isWhatsapp: Boolean,
    href: String,
  },
};
</script>

<style lang="scss">
.button,
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.button {
  font-family: inherit;
  border-radius: 10px;
  background-color: $color-accent;
  color: $color-white;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.65px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  height: 51px;

  @media (min-width: $screen-md) {
    height: 68px;
    font-size: 17px;
    line-height: 17px;
    padding-left: 62px;
    padding-right: 62px;
  }

  &:hover {
    background-color: $color-btn-hover;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &_disabled {
    opacity: 0.65;
    pointer-events: none;
  }

  &_phone,
  &_sms,
  &_telegram,
  &_whatsapp {
    svg {
      position: relative;
      top: -2px;
      margin-left: 8px;
    }
  }

  &_telegram {
    & {
      background-color: rgba(78, 159, 219, 1);
    }

    &:hover {
      background-color: #2b88cd;
    }
  }

  &_whatsapp {
    & {
      background-color: #25d366;
    }

    &:hover {
      background-color: #1fab54;
    }
  }

  @include click-element(0.4rem);
}

a.button {
  text-decoration: none;
}

.content {
  position: relative;
  top: 3px;
}
</style>
