<template>
  <Layout :fullHeight="true">
    <InnerPage
      center
      title="Ваша карта готова!"
      :subTitle="walletsLink
    ? 'Загрузка произойдет\nчерез несколько секунд.'
    : 'Используйте ваш номер телефона\nв качестве номера карты.'"
    >
      <div class="card">
        <LayoutCard />
      </div>
    </InnerPage>
  </Layout>
</template>

<script>
import { mapState } from "vuex";

import Layout from "@/components/Layout.vue";
import InnerPage from "@/components/InnerPage.vue";
import LayoutCard from "@/components/LayoutCard.vue";

export default {
  name: "Main",

  components: {
    Layout,
    InnerPage,
    LayoutCard,
  },

  computed: {
    ...mapState({
      walletsLink: state => state.client.walletsLink,
    })
  },
};
</script>
