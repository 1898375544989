<template>
  <label class="checkbox" :class="{ disabled, error, asFormControl }">
    <input
      type="checkbox"
      :disabled="disabled"
      class="checkbox__control"
      v-bind="$attrs"
      v-bind:checked="value"
      @change="$emit('input', !value)"
    />
    <div class="checkbox__wrapper">
      <div class="checkbox__box">
        <IconApply v-if="value && !asFormControl" />
        <IconApplyLarge v-if="value && asFormControl" />
        <IconX v-if="!value && disabled && asFormControl" />
      </div>

      <div class="checkbox__label">
        <slot />
        <div v-if="asFormControl && notice" class="checkbox__notice">
          {{notice}}
        </div>
      </div>

      <GiftBall
        v-if="asFormControl && giftBall && !disabled"
        :value="giftBall"
        class="checkbox__gift"
      />
    </div>
  </label>
</template>

<script>
import GiftBall from "@/components/ui/GiftBall.vue";
import IconApply from "@/icons/IconApply.vue";
import IconApplyLarge from "@/icons/IconApplyLarge.vue";
import IconX from "@/icons/IconX.vue";

export default {
  name: "Checkbox",
  components: { GiftBall, IconApply, IconApplyLarge, IconX },
  props: {
    disabled: Boolean,
    checked: Boolean,
    error: Boolean,
    value: Boolean,
    notice: String,
    giftBall: Number,
    asFormControl: Boolean,
  },
};
</script>
<style lang="scss">
.checkbox {
  user-select: none;
  font-size: 12px;
  line-height: 1.4;
  color: $color-text-pseudo;
  cursor: pointer;
  position: relative;

  &.disabled {
    pointer-events: none;
    user-select: none;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__control {
    display: none;
  }

  &__box {
    width: 21px;
    height: 21px;
    border-radius: 5px;
    background-color: #edf1f7;
    flex: 0 0 auto;
    margin-right: 13px;

    box-sizing: border-box;
    transition: 0.15s ease-in-out;
    position: relative;

    svg {
      position: absolute;
      left: calc(50% + 1px);
      top: calc(50% + 1px);
      transform: translate(-50%, -50%);
    }

    @include click-element(0.4rem);
  }

  &__notice {
    font-size: 12px;
    font-weight: normal;
  }
}

.checkbox.asFormControl {
  display: block;
  border: 2px solid #EDF1F6;
  padding: 24px 16px;
  padding-bottom: 20px;
  border-radius: 12px;
}

.checkbox.asFormControl {
  .checkbox__label,
  .checkbox__notice {
    font-weight: 500;
    color: $color-text-pseudo;
    letter-spacing: 0.04em;
  }

  .checkbox__label {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    min-height: 32px;
    font-size: 18px;
    line-height: 23px;
  }

  .checkbox__notice {
    font-size: 15px;
    line-height: 26px;
  }

  .checkbox__box {
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 6px;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $color-accent;
    }
  }

  .checkbox__gift {
    margin-left: 16px;
  }

   &:active .checkbox__box {
    transform: scale(0.95);
  }
}

.checkbox.asFormControl.error {
  .checkbox__label {
    color: $color-error;
  }

  .checkbox__box {
    box-sizing: border-box;
    border: 1px solid $color-error;
  }
}

.checkbox.asFormControl.disabled {
  .checkbox__label,
  .checkbox__notice {
    color: $color-disabled;
  }

  .checkbox__box {
    background-color: transparent;

    svg {
      color: $color-disabled;
    }
  }
}
</style>
