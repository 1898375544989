import { emailReg } from './index';
import { validateDate } from './validateDate';

const validEmail = (email) => {
  return emailReg.test(email);
}

export const validateField = (field) => {
  let value;
  let error;

  if (["static-set", "set", "gender"].includes(field.type)) {
    value = field.value && field.value.value;
  } else {
    value = field.value;
  }

  if (field.code === "email") {
    if (field.value && !validEmail(field.value)) {
      error = `${field.title}. Ошибка ввода`;
    }
  }

  if (field.type === "children" && field.value) {
    value = field.value
      .map((item) => ({
        name: item.text,
        birthdate: item.date,
        disabled: item.disabled
      }))
      .filter(({ name }) => name);
  }

  if (["date", "birthdate"].includes(field.type) && field.value) {
    if (!validateDate(field.value)) {
      error = `${field.title}. Поле заполнено неверно`;
    }
  }

  if (field.required) {
    if (field.code === "email") {
      if (!field.value || !field.value.length) {
        error = "Укажите электронную почту";
      }
    }

    let v = (
      (typeof field.value === "object" &&
      field.value.hasOwnProperty("value")
        ? field.value.value || ""
        : field.value) || ""
    )
      .toString()
      .trim();

    if (!v || !v.length) {
      error = `${field.title}. Обязательно`;
    }

    if (field.code === "tel") {
      if (!field.value || field.value.length < 6) {
        error = "Укажите телефон";
      }
    }
  }

  return { value, error };
}
