<template>
  <div class="children">
    <Input
      v-for="(children, index) in childrens"
      :key="index"
      :name="field.code"
      :label="index === 0 ? field.title : ''"
      v-model="childrens[index]"
      :error="children.error"
      :placeholder="field.placeholder || 'Имя'"
      :required="field.required"
      :withDate="true"
      :withGaps="true"
      :disabled="field.disabled || children.disabled"
      :ref="`children-${index}`"
      @input="() => handleInput(index)"
      @onBlur="$emit('onBlur', $event)"
      :getSuggestionList="brand.dadataApiKey ? getSuggestionList : undefined"
    />

    <GiftBall
      v-if="giftBall && !disabled"
      class="children_input_gift"
      :value="giftBall"
      :hasInlineHint="true"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Input from "@/components/ui/Input.vue";
import GiftBall from "@/components/ui/GiftBall.vue";

import { fetchSuggestions } from '@/services/dadata';

export default {
  name: "ChildrenInput",
  props: {
    childrens: {
      type: Array,
      default: function() {
        return [{ text: "", date: "", disabled: false }];
      }
    },
    field: Object
  },
  computed: {
    ...mapState({
      brand: state => state.brand
    }),

    giftBall() {
      return this.field.bonus && this.field.bonus.amount;
    },

    disabled() {
      return this.field.disabled
    },
  },
  components: {
    Input,
    GiftBall,
  },
  methods: {
    handleInput(index) {
      let stateChildren = [...this.childrens];
      if (stateChildren.every(({ text }) => !!text)) {
        stateChildren.push({});
      } else {
        let isFirstEmptyField = true;
        stateChildren = stateChildren.filter(({ text }, childrenIndex) => {
          if (!isFirstEmptyField && !text) {
            if (index === childrenIndex) {
              const el = this.$refs[`children-${childrenIndex - 1}`][0].$el;
              const input = el.querySelector("input");
              input.focus();
            }
            return false;
          }
          if (!text) {
            isFirstEmptyField = false;
          }
          return true;
        });
      }

      this.$emit("input", stateChildren);
    },

    getSuggestionList(query) {
      const apiKey = this.brand.dadataApiKey;

      return fetchSuggestions(apiKey, 'name', query)
        .then(({ suggestions }) => suggestions.map((s) => ({
          label: s.value,
          value: s.value,
        })));
    }
  }
};
</script>

<style lang="scss" scoped>
  .children {
    .input + .input {
      margin-top: 21px;
    }
  }

  .children_input_gift {
    display: block;
    margin-top: 21px;
  }
</style>
