var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input",class:{ disabled: _vm.disabled, withChange: _vm.withChange, gaps: _vm.withGaps }},[(_vm.label || typeof _vm.error === 'string')?_c('label',{staticClass:"input__label",class:{ required: _vm.required && !_vm.error, error: _vm.error }},[_vm._v(_vm._s(_vm.error || _vm.label))]):_vm._e(),_c('div',{staticClass:"input__wrapper",class:{ error: _vm.error, focus: _vm.isFocus }},[(_vm.hintPlaceholder)?_c('div',{staticClass:"input__part input__hint-placeholder"},[_c('input',{staticClass:"input__control input__hint-placeholder",attrs:{"type":"text","disabled":"","placeholder":_vm.getHintPlaseholder}})]):_vm._e(),(_vm.mask)?_c('div',{staticClass:"input__part"},[_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"input__control",class:{ center: _vm.center, disabled: _vm.disabled },attrs:{"type":"text","required":_vm.required,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"focus":_vm.handleFocus,"blur":_vm.handleBlur}},'input',_vm.$attrs,false))]):(_vm.withDate)?[_c('div',{staticClass:"input__part",class:{ autocomplete: !!_vm.getSuggestionList, opened: _vm.opened }},[(!!_vm.getSuggestionList)?_c('vue-simple-suggest',{class:{ opened: _vm.opened },attrs:{"value":_vm.value && _vm.value.text,"value-attribute":"value","display-attribute":"label","list":_vm.getSuggestionList,"debounce":300,"filter-by-query":false},on:{"input":function($event){return _vm.$emit('input', {
            text: $event,
            date: _vm.value.date,
            disabled: _vm.disabled,
          })},"focus":_vm.handleFocus,"blur":_vm.handleBlur,"show-list":function () { return _vm.opened = true; },"hide-list":function () { return _vm.opened = false; }}},[_c('input',_vm._b({staticClass:"input__control",class:{ center: _vm.center, disabled: _vm.disabled, error: _vm.value && _vm.value.textError },attrs:{"required":_vm.required,"disabled":_vm.disabled,"autocomplete":"off"},domProps:{"value":_vm.value && _vm.value.text}},'input',_vm.$attrs,false))]):_c('input',_vm._b({staticClass:"input__control",class:{ center: _vm.center, disabled: _vm.disabled, error: _vm.value && _vm.value.textError },attrs:{"type":"text","required":_vm.required,"disabled":_vm.disabled},domProps:{"value":_vm.value && _vm.value.text},on:{"input":function($event){return _vm.$emit('input', { text: $event.target.value, date: _vm.value.date, disabled: _vm.disabled })},"focus":_vm.handleFocus,"blur":_vm.handleBlur}},'input',_vm.$attrs,false))],1),_c('div',{staticClass:"input__part"},[_c('div',{staticClass:"input__part input__hint-placeholder input__native-date"},[_c('input',{staticClass:"input__control input__hint-placeholder input__native-date",attrs:{"type":"text","disabled":"","placeholder":_vm.getHintDatePlaseholder}})]),_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##.##.####'),expression:"'##.##.####'"}],staticClass:"input__control input__native-date",class:{ center: _vm.center, disabled: _vm.disabled, error: _vm.value && _vm.value.dateError },attrs:{"type":"text","required":_vm.required,"disabled":_vm.disabled,"placeholder":"","pattern":"[0-9]*","inputmode":"numeric"},domProps:{"value":_vm.value && _vm.value.date},on:{"input":function($event){return _vm.$emit('input', {
              text: _vm.value.text,
              date: $event.target.value,
              disabled: _vm.disabled
            })},"focus":_vm.handleFocus,"blur":_vm.handleBlur}},'input',_vm.$attrs,false))])]:_c('div',{staticClass:"input__part"},[_c('input',_vm._b({staticClass:"input__control",class:{ center: _vm.center, disabled: _vm.disabled },attrs:{"type":"text","required":_vm.required,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"focus":_vm.handleFocus,"blur":_vm.handleBlur}},'input',_vm.$attrs,false))]),(_vm.giftBall && !_vm.disabled)?_c('GiftBall',{staticClass:"input__part input__part_gift",attrs:{"value":_vm.giftBall}}):_vm._e(),(_vm.withChange)?_c('div',{staticClass:"input__part input__part_change"},[_c('div',{staticClass:"input__change",on:{"click":_vm.handleClickChange}},[_c('span',{staticClass:"link"},[_vm._v("Изменить")])])]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }