<template>
  <form
    class="form"
    @submit="checkForm"
    novalidate="true"
    @input="handleInputForm"
    v-bind="$attrs"
    ref="form"
  >
    <div class="form__fields" v-if="fields && fields.length">
      <div class="form__field" v-for="(field, index) in fields" :key="index">
        <TelInput
          v-if="['tel', 'phoneNumber'].includes(field.type)"
          :gift-ball="field.bonus && field.bonus.amount"
          :label="field.title || fieldsLabel[field.code]"
          v-model="field.value"
          :ref="`field-${index}`"
          :disabled="field.disabled"
          :error="field.error"
          :name="field.code"
          :required="field.required"
          @input="() => handleInputField(field)"
          @onBlur="() => handleBlurField(field)"
        />
        <DateInput
          v-else-if="['date', 'birthdate'].includes(field.type)"
          :gift-ball="field.bonus && field.bonus.amount"
          :label="field.title"
          v-model="field.value"
          :ref="`field-${index}`"
          :disabled="field.disabled"
          :error="field.error"
          :name="field.code"
          :required="field.required"
          @input="() => handleInputField(field)"
          @onBlur="() => handleBlurField(field)"
        />
        <Dropdown
          v-else-if="['static-set', 'set'].includes(field.type)"
          v-model="field.value"
          :options="getOptions(field.options)"
          :error="field.error"
          :required="field.required"
          :placeholder="field.placeholder"
          :label="field.title || fielsLabel[field.code]"
          :gift-ball="field.bonus && field.bonus.amount"
          :disabled="field.disabled"
          :name="field.code"
          :ref="`field-${index}`"
          @input="() => handleInputField(field)"
          @onBlur="() => handleBlurField(field)"
        />
        <Dropdown
          v-else-if="['gender'].includes(field.type)"
          v-model="field.value"
          :options="getOptionsGender()"
          :error="field.error"
          :required="field.required"
          :placeholder="field.placeholder"
          :label="field.title || fieldsLabel[field.code]"
          :gift-ball="field.bonus && field.bonus.amount"
          :disabled="field.disabled"
          :name="field.code"
          :ref="`field-${index}`"
          @input="() => handleInputField(field)"
          @onBlur="() => handleBlurField(field)"
        />
        <ChildrenInput
          v-else-if="field.type === 'children'"
          :ref="`field-${index}`"
          :childrens="field.value"
          :field="field"
          :name="field.code"
          :disabledForm="disabledForm"
          @input="value => handleInputChildren(field, value)"
          @onBlur="() => handleBlurField(field)"
        />
        <Input
          v-else-if="field.type === 'numeric' && field.code !== 'cardText'"
          :name="field.code"
          :label="field.title || fieldsLabel[field.code]"
          v-model="field.value"
          :error="field.error"
          :placeholder="field.placeholder"
          :gift-ball="field.bonus && field.bonus.amount"
          :required="field.required"
          :disabled="field.disabled"
          :ref="`field-${index}`"
          pattern="[0-9]*"
          inputmode="numeric"
          @input="() => handleInputField(field)"
          @onBlur="() => handleBlurField(field)"
        />
        <Autocomplete
          v-else-if="autocompleteFields.includes(field.code) && brand.dadataApiKey"
          :name="field.code"
          :label="field.title || fieldsLabel[field.code]"
          v-model="field.value"
          :error="field.error"
          :placeholder="field.placeholder"
          :gift-ball="field.bonus && field.bonus.amount"
          :required="field.required"
          :disabled="field.disabled"
          :ref="`field-${index}`"
          @input="() => handleInputField(field)"
          @blur="() => handleBlurField(field)"
          :getSuggestionList="(query) => getSuggestionList(field, query)"
        />
        <Checkbox
          v-else-if="checkboxFields.includes(field.type)"
          v-model="field.value"
          :asFormControl="true"
          :name="field.code"
          :error="field.error"
          :gift-ball="field.bonus && field.bonus.amount"
          :required="field.required"
          :disabled="field.disabled"
          :notice="field.notice"
          :ref="`field-${index}`"
        >
          {{field.title || fieldsLabel[field.code]}}
        </Checkbox>
        <Input
          v-else
          :name="field.code"
          :label="field.title || fieldsLabel[field.code]"
          v-model="field.value"
          :error="field.error"
          :placeholder="field.placeholder"
          :gift-ball="field.bonus && field.bonus.amount"
          :required="field.required"
          :disabled="field.disabled"
          :ref="`field-${index}`"
          @input="() => handleInputField(field)"
          @onBlur="() => handleBlurField(field)"
        />
      </div>
    </div>
    <div class="form__submit">
      <Button
        type="submit"
        :disabled="isValidate"
      >
        <loader v-if="loading" :active="true" color="#fff" loader="dots" :width="40" :height="20" />
        <template v-else>ПОЛУЧИТЬ КАРТУ</template>
      </Button>
    </div>
    <div v-if="error" class="form__error">{{ error }}</div>
  </form>
</template>

<script>
import Input from "@/components/ui/Input.vue";
import Button from "@/components/ui/Button.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import Checkbox from "@/components/ui/Checkbox.vue";
import Autocomplete from "@/components/ui/Autocomplete.vue";
import TelInput from "@/components/TelInput.vue";
import DateInput from "@/components/DateInput.vue";
import ChildrenInput from "@/components/ChildrenInput.vue";

import { mapState } from "vuex";

import { fetchSuggestions } from '@/services/dadata';
import { validateField } from "@/utils/validateField";
import { validateChildren } from "@/utils/validateChildren";

export default {
  name: "ClientForm",

  components: {
    Input,
    Button,
    TelInput,
    Checkbox,
    Autocomplete,
    Dropdown,
    ChildrenInput,
    DateInput,
  },

  props: {
    fields: Array,
    validationErrors: Array,
    error: String,
    loading: Boolean,
    disabled: Boolean
  },

  data: () => ({
    disabledForm: false,
    fieldsLabel: {
      tel: "Мобильный телефон",
      name: "ФИО",
      email: "E-mail",
      city: "Город"
    },
    autocompleteFields: [
      'fullName',
      'name',
      'surname',
      'patronymic',
      'email',
    ],
    checkboxFields: [
      'checkbox',
      'subscribed',
    ],
  }),

  watch: {
    validationErrors() {
      if (!this.validationErrors) return;

      const validationErrorsCodes = this.validationErrors.map(
        ({ code }) => code
      );

      for (const field of this.fields) {
        if (validationErrorsCodes.includes(field.code)) {
          const error = this.validationErrors.find(el => el.code === field.code)
            .errorMessage;
          field.error = `${field.title}. ${error}`;
        }
      }
    },
  },

  created() {
    for (const field of this.fields) {
      if (field.type === "set") {
        this.$store.dispatch("GET_OPTIONS", { field: field.code }).then(() => {
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        });
      }
    }
  },

  mounted() {
    setTimeout(() => {
      let index = 0;
      for (const field of this.fields) {
        if (!field.disabled) {
          const el = this.$refs[`field-${index}`][0];
          if (!el) continue;
          const input = el.$el.querySelectorAll("input:not([disabled])");
          if (input[0]) { input[0].focus(); }
          return;
        }
        index += 1;
      }
    }, 150);
  },

  computed: {
    ...mapState({
      form: state => state.form,
      brand: state => state.brand
    }),
    isValidate() {
      return this.disabled || this.disabledForm || this.loading;
    }
  },

  methods: {
    getOptions(options) {
      if (!options) {
        return [];
      }

      return options.map(value => ({ value, label: value }));
    },

    getOptionsGender() {
      return [
        { value: "1", label: "Мужской" },
        { value: "2", label: "Женский" }
      ];
    },

    checkForm(e) {
      e.preventDefault();

      if (this.isValidate) {
        return;
      }

      const form = {};
      const response = {};

      for (const field of this.fields) {
        form[field.code] = field;
        field.error = null;

        const { value, error } = validateField(field);
        response[field.code] = value;
        field.error = error;

        validateChildren(field);
      }

      if (this.fields.some(field => field.error)) {
        this.disabledForm = true;
        this.focusOnFieldWithError();
        return;
      }

      this.$emit("submit", response);
    },

    focusOnFieldWithError() {
      const fieldWithError = this.fields.find(({ error }) => error);
      let input;

      if (fieldWithError.code === 'children') {
        input = this.$refs.form.querySelector('.children .error');
      } else {
        input =
          this.$refs.form.querySelector(`input[name=${fieldWithError.code}]`) ||
          this.$refs.form.querySelector(`[name=${fieldWithError.code}] input`);
      }

      input && input.focus();
    },

    handleInputForm() {
      this.disabledForm = false;
    },

    handleInputField(field) {
      this.disabledForm = false;

      if (field.error) {
        field.error = undefined;
      }
    },

    handleBlurField(field) {
      const { error } = validateField(field);

      if (error) {
        field.error = error;
        this.$forceUpdate();
      }

      validateChildren(field);
    },

    handleInputChildren(field, value) {
      this.disabledForm = false;
      field.value = value;
    },

    getSuggestionList(field, query) {
      const apiKey = this.brand.dadataApiKey;

      return fetchSuggestions(apiKey, field.code, query)
        .then(({ suggestions }) => suggestions.map((s) => ({
          label: s.value,
          value: s.value,
        })));
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  &__fields {
    display: flex;
    flex-direction: column;
  }

  &__field + &__field {
    margin-top: 11px;
    @media (min-width: $screen-md) {
      margin-top: 21px;
    }
  }

  &__error {
    color: $color-error;
    text-align: center;
    margin-top: 10px;
  }

  &__submit {
    margin-top: 22px;

    @media (min-width: $screen-md) {
      margin-top: 47px;
    }
  }
}
</style>
