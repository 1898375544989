import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/Index.vue";
import ErrorPage from "@/views/ErrorPage.vue";

Vue.use(VueRouter);

const routes = [
  { path: '/verify-phone' },
  {
    path: "/:brand",
    name: "main",
    component: Index
  },
  {
    path: "/:brand/*",
    component: ErrorPage,
    props: { code: "404", text: "Страница не найдена" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
