<template>
  <form
    class="form"
    @submit="checkForm"
    novalidate="true"
    @input="handleInputForm"
    v-bind="$attrs"
    ref="form"
  >
    <div class="form__wrapper">
      <div>
        <div class="form__field">
          <TelInput
            v-model="phoneNumber"
            label="Мобильный телефон"
            name="phoneNumber"
            required
            :error="phoneNumberError"
            @input="() => handleInputField()"
            @onBlur="() => handleBlurField()"
            autofocus
          />
        </div>

        <div class="form__phone_submit">
          <Button
            type="submit"
            :disabled="isValidate"
            :isPhone="!loading && isPhone"
            :isSMS="!loading && isSMS"
          >
            <loader
              v-if="loading"
              :active="true"
              color="#fff"
              loader="dots"
              :width="40"
              :height="20"
            />
            <template v-else>{{ defaultSubmitLabel }}</template>
          </Button>
        </div>
      </div>

      <div v-if="error" class="form__error">{{ error }}</div>

      <div class="social" v-if="verifyByTelegram || verifyByWhatsapp">
        <span>Через</span>
        <a
          v-if="verifyByTelegram"
          class="link social__icon"
          href="/verify-phone?by=telegram"
          ><IconTelegram /> Telegram
        </a>

        <template v-if="verifyByWhatsapp">
          <span v-if="verifyByTelegram">или</span>
          <a class="link social__icon" href="/verify-phone?by=whatsapp">
            <IconWhatsapp /> WhatsApp
          </a>
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import Button from "@/components/ui/Button.vue";
import TelInput from "@/components/TelInput.vue";
import IconTelegram from "@/icons/IconTelegram.vue";
import IconWhatsapp from "@/icons/IconWhatsapp.vue";

import { mapState } from "vuex";

import { validateField } from "@/utils/validateField";

export default {
  name: "AuthForm",

  components: {
    Button,
    TelInput,
    IconTelegram,
    IconWhatsapp,
  },

  props: {
    termsLink: String,
    error: String,
    loading: Boolean,
    disabled: Boolean,
  },

  data: () => ({
    disabledForm: false,
    phoneNumber: "",
    phoneNumberError: "",
  }),

  computed: {
    ...mapState({
      form: (state) => state.form,
      brand: (state) => state.brand,
      agreedWithTerms: (state) => state.agreedWithTerms,
    }),

    channel() {
      return this.form.sendCode.channel || this.brand.codeAdapter;
    },

    defaultSubmitLabel() {
      //if (this.channel === "flash_call") {
      //  return "ЧЕРЕЗ ЗВОНОК";
      //}
      return "ПОЛУЧИТЬ КОД";
    },

    isPhone() {
      return this.channel === "flash_call";
    },

    isSMS() {
      return this.channel === "sms";
    },

    verifyByTelegram() {
      return this.brand.verifyMethods.includes("telegram");
    },

    verifyByWhatsapp() {
      return this.brand.verifyMethods.includes("whatsapp");
    },

    isValidate() {
      return (
        this.disabled ||
        this.phoneNumber.length < 18 ||
        this.disabledForm ||
        !this.agreedWithTerms ||
        this.loading
      );
    },
  },

  methods: {
    checkForm(e) {
      e.preventDefault();

      if (this.isValidate) {
        return;
      }

      const response = {};

      const { value, error } = validateField({
        code: "phoneNumber",
        type: "phoneNumber",
        required: true,
        value: this.phoneNumber,
      });

      if (error) {
        this.phoneNumberError = error;
        this.disabledForm = true;
        this.focusOnField();
        return;
      } else {
        this.phoneNumberError = "";
        response.phoneNumber = value;
      }

      this.$emit("submit", {
        ...response,
        agreedWithTerms: this.agreedWithTerms,
      });
    },

    focusOnField() {
      const input =
        this.$refs.form.querySelector("input[name=phoneNumber]") ||
        this.$refs.form.querySelector("[name=phoneNumber] input");

      input && input.focus();
    },

    handleInputForm() {
      this.disabledForm = false;
    },

    handleInputField() {
      this.disabledForm = false;
      this.phoneNumberError = "";
    },

    handleBlurField() {
      const { error } = validateField({
        code: "phoneNumber",
        type: "phoneNumber",
        required: true,
        value: this.phoneNumber,
      });

      if (error) {
        this.phoneNumberError = error;
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  @media (max-width: $screen-md) {
    margin-top: 27px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-md) {
      height: 370px;
      justify-content: center;
    }
  }

  &__phone_submit,
  &__field + &__field {
    margin-top: 11px;

    @media (min-width: $screen-md) {
      margin-top: 21px;
    }
  }

  &__error,
  &__agree {
    margin-top: 31px;

    @media (min-width: $screen-md) {
      margin-top: 20px;
    }
  }

  &__error {
    color: $color-error;
    text-align: center;
  }
}

.action-button {
  margin-top: 12px;
}

.social {
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;

  color: $color-text-pseudo;
  font-size: 16px;
  line-height: 28px;

  margin-top: 30px;

  span {
    flex-shrink: 0;
  }

  &__icon {
    display: flex;
    align-items: center;
    column-gap: 6px;

    svg {
      width: 28px;
      height: 28px;
    }

    path {
      fill: #3366ff;
    }
  }

  @media (min-width: $screen-md) {
    margin-top: 40px;
    flex-wrap: nowrap;
  }
}
</style>
