<template>
  <span v-bind="$attrs">
    <span
      @mouseover="mouseOver"
      @mouseout="mouseOut"
      class="giftball"
    >
      +{{ value }} <div class="giftball__icon"><IconGiftSmall /></div>

      <div
        v-show="!hasInlineHint && active"
        class="giftball__hint"
      >
        {{hintText}}
      </div>
    </span>

    <span v-if="hasInlineHint" class="giftball__hint_inline">
      {{hintText}}
    </span>
  </span>
</template>

<script>
import IconGiftSmall from "@/icons/IconGiftSmall.vue";

export default {
  name: "GiftBall",
  components: { IconGiftSmall },
  props: {
    value: Number,
    hasInlineHint: Boolean,
  },
  data: () => ({
    active: false,
    hintText: "Получите бонус за заполнение поля",
  }),
  methods: {
    mouseOver() {
      this.active = true;
    },
    mouseOut() {
      this.active = false;
    },
  },
}
</script>

<style lang="scss">
.giftball {
  display: inline-flex;
  align-items: center;
  padding: 8px 13px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  background: $color-accent;
  border-radius: 30px;
  cursor: default;
  position: relative;

  svg {
    position: relative;
    top: -1px;
  }
}

.giftball,
.giftball__icon {
  line-height: 0;
}

.giftball__icon {
  margin-left: 6px;
}

.giftball__hint {
  position: absolute;
  right: 0;
  top: -26px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0;
  color: #72747a;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 11px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.21);
  padding: 4px 11px;
  z-index: 1;
  width: 209px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    right: 16px;
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    transform: rotate(45deg);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.21);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    right: 15px;
    height: 10px;
    width: 10px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }
}

.giftball__hint_inline {
  display: inline-block;
  font-size: 14px;
  color: #72747a;
  font-weight: 500;
  margin: 0;
  margin-left: 8px;
  position: relative;
  top: 2px;
}
</style>
