<template>
  <div>
    <Spinner v-if="loading" />
    <AuthForm
      :error="form.sendCode.error"
      @submit="handleSubmit"
      :loading="form.sendCode.status === 'loading'"
      :termsLink="brand.termsLink"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AuthForm from "@/components/AuthForm.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "SendCodeForm",

  components: {
    AuthForm,
    Spinner
  },

  props: {
    loading: Boolean,
    disabled: Boolean,
  },

  computed: {
    ...mapState({
      form: state => state.form,
      brand: state => state.brand
    }),
  },

  beforeCreate() {
    localStorage.setItem("currentStep", "init");
  },

  methods: {
    getTokenSendCode() {
      return this.$recaptcha("send_code");
    },

    handleSubmit(form) {
      this.$store
        .dispatch("SEND_CODE", { form, getToken: this.getTokenSendCode })
        .then(() => {
          this.$nextTick(() => {
            this.$forceUpdate();

            this.$store.state.form.sendCode.status === "success" &&
              this.$emit("submit");
          });
        });
    }
  }
};
</script>
