<template>
  <Layout with-card>
    <InnerPage
      :title="
        showSendCodeForm || showSubmitPhoneForm || showSmsCodeForm
          ? 'Получить карту'
          : 'Добро пожаловать!\nДозаполните поля'
      "
    >
      <div class="form">
        <CommunicationTypes
          v-if="showSendCodeForm"
          :handleSelectSms="handleShowSendCodeForm"
          :verifyMethods="this.brand.verifyMethods"
          :termsLink="brand.termsLink"
        />

        <!-- Форма ввода телефона  -->
        <SendCodeForm
          v-else-if="showSmsCodeForm"
          :stateForm="sendCode"
          :brand="brand"
          :loading="loading"
          :disabled="brandStatus !== 'success'"
          @submit="handleSubmitSendCodeForm"
        />

        <!-- Форма подтверждения телефона через sms-код -->
        <SubmitPhoneForm
          v-else-if="showSubmitPhoneForm"
          @cancel="handleCancelTel"
          @confirm="handleConfirm"
        />

        <!-- Форма регистрации нового клиента -->
        <RegistrationForm
          v-else
          @submit="handleSubmitRegistration"
          @cancel="handleCancelTel"
        />
      </div>
    </InnerPage>
  </Layout>
</template>

<script>
import { mapState } from "vuex";

import Layout from "@/components/Layout.vue";
import InnerPage from "@/components/InnerPage.vue";
import CommunicationTypes from "@/components/CommunicationTypes.vue";

import RegistrationForm from "@/views/Form/RegistrationForm.vue";
import SendCodeForm from "@/views/Form/SendCodeForm.vue";
import SubmitPhoneForm from "@/views/Form/SubmitPhoneForm.vue";

export default {
  name: "Main",
  data: () => ({
    currentStep: localStorage.getItem("currentStep") || "init",
    loading: true,
  }),
  components: {
    Layout,
    InnerPage,
    SendCodeForm,
    RegistrationForm,
    SubmitPhoneForm,
    CommunicationTypes,
  },
  beforeCreate() {
    const { brand = "" } = this.$route.params;
    const { __gebugImg, __debugColor } = this.$route.query;

    this.$store.dispatch("GET_BRAND", {
      formCode: brand,
      __gebugImg,
      __debugColor: __debugColor && `#${__debugColor}`,
    });

    this.$store.subscribe((mutation, state) => {
      const { type, payload = {} } = mutation;

      if (type === "SET_BRAND" && payload.fetched) {
        if (payload.phoneNumberVerified) {
          this.currentStep = "registrated";
        }

        this.loading = false;
      }

      if (type === "SET_BRAND_STATUS" && payload.status === "fail") {
        this.loading = false;
      }
    });
  },
  async created() {
    await this.$recaptchaLoaded();
    await this.$recaptcha("homepage");
  },
  computed: {
    showSendCodeForm() {
      return this.currentStep === "init";
    },

    showSmsCodeForm() {
      return this.currentStep === "smsCodeForm";
    },

    showSubmitPhoneForm() {
      return this.currentStep === "smsCode";
    },
    ...mapState({
      brand: (state) => state.brand,
      brandStatus: (state) => state.brand.status,
      client: (state) => state.client,
      sendCode: (state) => state.form.sendCode,
    }),
  },
  methods: {
    handleSubmitRegistration() {
      this.$forceUpdate();
      this.$nextTick(() => {
        const walletsLink = this.client.walletsLink;
        this.redirect(walletsLink);
      });
    },

    handleSubmitSendCodeForm() {
      this.currentStep = "smsCode";
    },

    handleShowSendCodeForm() {
      this.currentStep = "smsCodeForm";
    },
    handleCancelTel() {
      this.currentStep = "smsCodeForm";
    },
    handleConfirm() {
      this.currentStep = "registrated";
    },
    redirect(walletsLink) {
      localStorage.setItem("currentStep", "init");

      if (!walletsLink) {
        this.$router.push({ path: this.$route.path + "#thankyou" });
        return;
      }

      if (!this.$device.mobile) {
        this.$router.push({ path: this.$route.path + "#qr" });
      } else {
        this.$router.push({ path: this.$route.path + "#thankyou" });
        setTimeout(() => {
          document.location.href = walletsLink;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  max-width: 380px;
  position: relative;
  z-index: 10;

  @media (max-width: $screen-md) {
    margin: 27px auto 0 auto;
  }
}

.layout-card {
  position: relative;
  margin: 56px auto;
  padding: 0 44px;
  max-width: 410px;

  @media (min-width: $screen-md) {
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    max-width: none;
    width: 60%;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(3);
    background: url("data:image/svg+xml,%3Csvg data-v-c1f1971a='' xmlns='http://www.w3.org/2000/svg' width='687' height='687' viewBox='0 0 687 687' data-v-005bfc5a=''%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='345' cy='343.5' r='192.375' stroke='%233F35E2' stroke-width='.75' opacity='.159'%3E%3C/circle%3E%3Ccircle cx='342.75' cy='342.75' r='266.625' stroke='%233F35E2' stroke-width='.75' opacity='.147'%3E%3C/circle%3E%3Ccircle cx='343.5' cy='343.5' r='342.375' stroke='%233F35E2' stroke-width='.75' opacity='.092'%3E%3C/circle%3E%3Ccircle cx='342.75' cy='342.75' r='144' fill='%23DFE4F2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E")
      no-repeat center / contain;

    @media (min-width: $screen-md) {
      transform: translate(-50%, -50%) scale(1);
      top: 50%;
      left: 50%;
      height: 686px;
      width: 686px;
    }
  }

  &__preview {
    width: 100%;
    z-index: 2;

    position: relative;
    @media (min-width: $screen-md) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__bg {
    z-index: 1;
  }
}
</style>
