<template>
  <div class="wrapper">
    <div>
      <div v-for="(method, index) in verifyMethods" :key="index">
        <Button
          v-if="method === 'telegram'"
          class="action-button"
          href="/verify-phone?by=telegram"
          isTelegram
          :disabled="!agreedWithTerms"
        >
          ЧЕРЕЗ ТЕЛЕГРАМ
        </Button>

        <Button
          v-if="method === 'whatsapp'"
          class="action-button"
          href="/verify-phone?by=whatsapp"
          isWhatsapp
          :disabled="!agreedWithTerms"
        >
          ЧЕРЕЗ WHATSAPP
        </Button>

        <Button
          v-if="method === 'sms'"
          class="action-button"
          @click="handleSelectSms"
          isSMS
          :disabled="!agreedWithTerms"
          >ЧЕРЕЗ SMS</Button
        >
      </div>
    </div>

    <div class="checkbox">
      <Checkbox @input="handleClickChange" :value="agreedWithTerms">
        <span v-html="termsLink"></span>
        <span v-if="!termsLink" style="color: #ff6767">
          Текст требует согласования с партнером
        </span>
      </Checkbox>
    </div>
  </div>
</template>

<script>
import Button from "@/components/ui/Button.vue";
import Checkbox from "@/components/ui/Checkbox.vue";

import { mapState } from "vuex";

export default {
  name: "CommunicationTypes",

  props: {
    termsLink: String,
    handleSelectSms: Function,
    verifyMethods: Array | String,
  },

  methods: {
    handleClickChange(value) {
      this.$store.commit("SET_AGREED_WITH_TERMS", value);
    },
  },
  computed: {
    ...mapState({
      agreedWithTerms: (state) => state.agreedWithTerms,
    }),
  },

  components: {
    Button,
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  margin-bottom: 12px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  @media (min-width: $screen-md) {
    height: 370px;
    justify-content: center;
    padding-top: 30px;
  }
}

.checkbox {
  margin-top: 12px;
}
</style>
