<template>
  <Input
    :placeholder="placeholder"
    :mask="mask"
    :hint-placeholder="true"
    v-bind="$attrs"
    v-bind:value="value"
    @input="handleInput"
    @onBlur="$emit('onBlur', $event)"
    maxlength="10"
    pattern="[0-9]*"
    inputmode="numeric"
  />
</template>

<script>
import Input from "@/components/ui/Input.vue";

export default {
  name: "DateInput",
  props: { value: String },
  data: () => ({
    placeholder: "01.01.1900",
    mask: "##.##.####"
  }),
  components: {
    Input
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
