<template>
  <Layout with-card :back="'/' + $route.params.brand">
    <InnerPage title="Для загрузки карты наведите камеру телефона на QR-код">
      <div class="qr">
        <QRCode :url="url" />
      </div>

      <div class="qr-timer">
        <div class="qr-timer__label">Или перейдите по ссылке в браузере вашего телефона:</div>
        <a class="timer__timeout link" :href="url" target="__blank">{{
          url && url.split("//")[1]
        }}</a>
      </div>
    </InnerPage>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import InnerPage from "@/components/InnerPage.vue";
import QRCode from "@/components/QRCode.vue";

export default {
  name: "QRCodePage",
  components: {
    Layout,
    InnerPage,
    QRCode
  },
  beforeCreate() {
    const { walletsLink } = this.$store.state.client;

    if (!walletsLink || this.$device.mobile) {
      this.$router.push("/" + this.$route.params.brand);
    }
  },
  created() {
    const { walletsLink } = this.$store.state.client;
    this.url = walletsLink;
  },
  data: () => ({
    url: null,
    timer: 30,
    disabled: false
  })
};
</script>

<style lang="scss" scoped>
.qr-timer {
  margin-top: 52px;
  text-align: center;

  @media (min-width: $screen-md) {
    text-align: left;
  }

  &__label {
    margin-bottom: 6px;
  }
}

.qr {
  position: relative;
  z-index: 10;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: $screen-md) {
    display: block;
  }
}
</style>
