<template>
  <div class="card">
    <div
      class="card__inner"
      @mousemove="handleMousemove"
      @mouseleave="handleMouseleave"
      @mouseenter="handleMouseenter"
      ref="card"
      :class="{ transition }"
    >
      <div v-if="!img" class="card__view card__view_default" />
      <div v-else class="card__view" :style="{ backgroundColor: bgColor }">
        <div class="card__img" :style="{ backgroundImage: `url(${img})` }" />
      </div>
      <div v-if="text" class="card__text" :style="{ color: textColor }">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    img: String,
    text: String,
    bgColor: String,
    textColor: String
  },
  data: () => ({
    listening: false,
    calculated: 0,
    transition: true
  }),
  created() {
    if (this.$device.mobile) {
      this.calculated = 1;
      this.transition = false;
    }
  },
  mounted() {
    if (this.$device.mobile && this.$device.android) {
      window.addEventListener("deviceorientation", this.handleOrientation);
    } else if (this.$device.mobile) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    handleMouseleave() {
      if (this.$device.mobile) return;
      this.listening = false;
      this.transition = true;
      this.calculated = 0;
      this.$refs.card.style.transform = "";
    },
    handleMouseenter() {
      if (this.$device.mobile) return;
      this.calculated = 0;
      this.listening = true;
      this.transition = false;
    },
    handleMousemove(evt) {
      if (!this.listening || this.$device.mobile) return;
      const { x, y } = this.compute(evt);

      this.dX = x;
      this.dY = y;

      this.$refs.card.style.transform = `rotate3d(${-y}, ${x}, 0, ${4 *
        this.calculated}deg)`;
    },
    handleOrientation({ beta = 0, gamma = 0 }) {
      const x = gamma * 0.01;
      const y = (beta + 30) * 0.01;
      const deg = 22 * (Math.abs(x) + Math.abs(y) / 2);

      this.X = x;
      this.Y = y;
      this.deg = deg;

      this.$refs.card.style.transform = `rotate3d(${y}, ${-x}, 0, ${5 +
        deg}deg)`;
    },
    handleScroll() {
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop
      );

      const maxDeg = 40;
      const deg = (maxDeg * scrollTop) / 200;

      this.$refs.card.style.transform = `rotate3d(-1, 0, 0, ${1 + deg}deg)`;
    },
    compute(evt) {
      const { clientX, clientY } = evt;
      const {
        top,
        left,
        width,
        height
      } = this.$refs.card.getBoundingClientRect();

      if (this.calculated < 1) {
        this.calculated += 0.02;
      }

      return {
        x: (((clientX - left) / width) * 2 - 1) * this.calculated,
        y: (((clientY - top) / height) * 2 - 1) * this.calculated
      };
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  perspective: 1000px;

  &__inner {
    width: 100%;
    max-width: 371px;

    border-radius: 10px;
    position: relative;
    box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;
    margin: 0 auto;

    transform: rotate3d(0, 0, 0, 0);

    &.transition {
      transition: 0.4s ease-in-out;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 63%;
    }
  }

  &__view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;

    &_default {
      background-image: linear-gradient(
        215deg,
        #ffffff -4%,
        rgba(241, 241, 241, 0.64) -4%
      );

      &::after {
        content: "";
        position: absolute;
        width: 26%;
        padding-top: 26%;
        background: white;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
      }
    }
  }

  &__img {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 80%;
    height: 55%;
  }

  &__text {
    position: absolute;
    bottom: 10%;
    left: 6%;
    right: 6%;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #777777;
    font-variant-numeric: tabular-nums;
  }
}
</style>
