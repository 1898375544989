<template>
  <div class="timer" :class="{ disabled }">
    <div v-if="!timeout" class="timer__text">{{ text }} {{ getTime }}</div>
    <div v-else class="timer__timeout link">
      <slot />
      <div v-if="$slots.reset" @click="handleReset">
        <slot name="reset" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timer",
  props: {
    time: Number,
    text: String,
    disabled: Boolean
  },
  data: () => ({
    stateTime: 0,
    timeout: false
  }),
  computed: {
    getTime() {
      const newTime = Math.max(this.time - this.stateTime, 0);
      const min = String(Math.floor(newTime / 60));
      const sec = String(newTime % 60);
      return `${min.length === 1 ? "0" + min : min}:${
        sec.length === 1 ? "0" + sec : sec
      }`;
    }
  },
  methods: {
    handleReset() {
      this.$emit("reset");
      this.stateTime = 0;
      this.timeout = false;
      this.startTimer();
    },
    startTimer() {
      const interval = setInterval(() => {
        if (this.stateTime < this.time) {
          this.stateTime += 1;
        } else {
          this.$emit("time-up");
          this.timeout = true;

          clearInterval(interval);
        }
      }, 1000);
    }
  },
  created() {
    this.startTimer();
  }
};
</script>
<style lang="scss" scoped>
.timer {
  display: inline-block;

  &__text {
    font-size: 14px;
    font-weight: 500;
    color: $color-text-pseudo;

    @media (min-width: $screen-md) {
      font-size: 16px;
      font-weight: normal;
    }
  }

  &__timeout {
    @include click-element();
    display: inline-block;
    font-size: 14px;
  }

  &.disabled {
    pointer-events: none;
  }
}
</style>
