import Vue from "vue";
import Vuex from "vuex";
import { errorMap } from "@/utils/error";
import app from "./axiosConfig";

Vue.use(Vuex);
const defaultTelMask = "+7 (###) ###-##-##";

const storageBrandName = localStorage.getItem("brandName");
const storageBrandLogo = localStorage.getItem("brandLogo");
const storageBrandCardColor = localStorage.getItem("brandCardColor");
const storageTermsLink = localStorage.getItem("termsLink");
const storagePhoneMask = localStorage.getItem("phoneMask");
const storageCodeAdapter = localStorage.getItem("codeAdapter");
//const storageVerifyTopMethod = localStorage.getItem("verifyTopMethod");
const storageVerifyMethods = localStorage.getItem("verifyMethods");
//const storageVerifyByTelegram =
//  localStorage.getItem("verifyByTelegram") === "true";
const storagePhoneNumberVerified =
  localStorage.getItem("phoneNumberVerified") === "true";
const storageDadataApiKey = localStorage.getItem("dadataApiKey");

const storageClientTel = localStorage.getItem("clientTel");
const storageClientWalletsLink = localStorage.getItem("clientWalletsLink");

const storageFields = JSON.parse(localStorage.getItem("fields"));
if (storageFields && Array.isArray(storageFields)) {
  storageFields.forEach((field) => {
    if (field.code === "children" && !field.value) {
      field.value = undefined;
    }
  });
}

const defaultCodeAdapter = "sms";
const defaultVerifyTopMethod = "telegram";
const defaultVerifyMethods = ["telegram", "whatsapp", "sms"];

const isProd = process.env.NODE_ENV === "production";

export const store = new Vuex.Store({
  state: {
    agreedWithTerms: true,
    brand: {
      name: "",
      logo: storageBrandLogo,
      cardColor: storageBrandCardColor,
      brandName: storageBrandName,
      termsLink: storageTermsLink,
      codeAdapter: storageCodeAdapter,
      // verifyTopMethod: storageVerifyTopMethod,
      // verifyByTelegram: storageVerifyByTelegram,
      verifyMethods: storageVerifyMethods,
      phoneNumberVerified: storagePhoneNumberVerified,
      dadataApiKey: storageDadataApiKey,
      authorized: false,
    },
    phoneMask: storagePhoneMask || "",
    fields: storageFields,
    fieldsError: [],
    client: {
      tel: storageClientTel,
      walletsLink: storageClientWalletsLink,
    },
    form: {
      clientSubmit: {},
      sendCode: {},
      submitPhone: {},
    },
    failedAction: {},
  },
  mutations: {
    SET_BRAND(state, data) {
      console.log("data", data);
      console.log("state", state);
      state.brand = { ...state.brand, ...data };
      localStorage.setItem("brandLogo", data.logo);
      localStorage.setItem("brandName", data.brandName);
      localStorage.setItem("brandCardColor", data.cardColor);
      localStorage.setItem("termsLink", data.termsLink);
      localStorage.setItem("codeAdapter", data.codeAdapter);
      //localStorage.setItem("verifyTopMethod", data.verifyTopMethod);
      localStorage.setItem("verifyMethods", JSON.stringify(data.verifyMethods));
      //localStorage.setItem("verifyByTelegram", data.verifyByTelegram);
      localStorage.setItem("phoneNumberVerified", data.phoneNumberVerified);
      localStorage.setItem("dadataApiKey", data.dadataApiKey);
    },
    SET_AGREED_WITH_TERMS(state, value) {
      state.agreedWithTerms = value;
    },
    SET_PHONEMASK(state, phoneMask) {
      const mask = phoneMask || defaultTelMask;
      state.phoneMask = mask;
      localStorage.setItem("phoneMask", mask);
    },
    SET_FIELDS(state, data) {
      const formattedFields = data.fields.map((field) => ({
        ...field,
        value: field.defaultValue,
      }));
      state.fields = formattedFields;

      localStorage.setItem("fields", JSON.stringify(formattedFields));
    },
    SET_CLIENT_FIELDS(state, data) {
      const formattedFields = state.fields.map((field) => {
        let value = data[field.code];

        if (
          ["checkbox", "subscribed"].includes(field.type) &&
          typeof value === "undefined"
        ) {
          value = field.defaultValue;
        }

        return {
          ...field,
          value,
          disabled:
            field.code === "children"
              ? field.disabled
              : field.disabled || !!data[field.code],
        };
      });

      state.fields = formattedFields;

      localStorage.setItem("fields", JSON.stringify(formattedFields));
    },
    SET_CLIENT(state, data) {
      state.client = { ...state.client, ...data };

      localStorage.setItem("clientTel", data.tel || state.client.tel);
      localStorage.setItem(
        "clientWalletsLink",
        data.walletsLink || state.client.walletsLink
      );
    },
    SET_BRAND_STATUS(state, data) {
      state.brand = { ...state.brand, ...data };
    },
    SET_FAILED_ACTION(state, data) {
      state.failedAction = { ...data };
    },
    SET_FORM_SUBMIT_CLIENT_STATUS(state, data) {
      state.form.clientSubmit = data;
    },
    SET_FORM_SEND_CODE_STATUS(state, data) {
      state.form.sendCode = data;
    },
    SET_FORM_SUBMIT_PHONE_STATUS(state, data) {
      state.form.submitPhone = data;
    },
    SET_OPTION(state, { field, options }) {
      state.fields = state.fields.map((el) => {
        if (el.code === field) {
          return {
            ...el,
            options,
          };
        }
        return el;
      });
    },
  },
  actions: {
    async GET_BRAND({ commit }, { formCode, __gebugImg, __debugColor }) {
      commit("SET_BRAND_STATUS", { status: "loading" });
      commit("SET_BRAND", {
        logo: null,
        cardColor: null,
      });

      let query;

      try {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        query = Object.fromEntries(params);
      } catch (e) {
        /* do nothing */
      }

      try {
        const {
          data: {
            appearance,
            dadataApiKey,
            phoneNumberVerified,
            client,
            fields,
          },
        } = await app.post(`start`, {
          formCode,
          query,
        });

        commit("SET_BRAND_STATUS", { status: "success" });
        commit("SET_BRAND", {
          logo: __gebugImg || appearance.logo,
          cardColor: __debugColor || appearance.cardColor,
          brandName: formCode,
          termsLink: appearance.termsLink,
          codeAdapter: appearance.codeAdapter || defaultCodeAdapter,
          //verifyTopMethod: appearance.verifyTopMethod || defaultVerifyTopMethod,
          verifyMethods: appearance.verifyMethods || defaultVerifyMethods,
          //verifyByTelegram: Boolean(appearance.verifyByTelegram),
          phoneNumberVerified,
          dadataApiKey,
          fetched: true,
        });
        commit("SET_PHONEMASK", appearance.phoneMask);
        commit("SET_FIELDS", {
          fields: fields.map((field) => {
            if (field.code === "children") {
              return { ...field, defaultValue: [field.defaultValue] };
            }

            if (["checkbox", "subscribed"].includes(field.type)) {
              return { ...field, defaultValue: Boolean(field.defaultValue) };
            }

            return field;
          }),
        });

        if (phoneNumberVerified) {
          const {
            walletsLink,
            isEmailSubscribed,
            isPhoneSubscribed,
            level,
            children,
            fields,
          } = client || {};

          if (children) {
            const prepateChildren = children.map((item) => ({
              text: item.name,
              date: item.birthdate,
              disabled: item.disabled || !!item.name,
            }));
            commit("SET_CLIENT_FIELDS", {
              children: prepateChildren,
              ...fields,
            });
          } else {
            commit("SET_CLIENT_FIELDS", { ...fields });
          }

          commit("SET_CLIENT", {
            tel: client.phoneNumber || "",
            walletsLink,
            isEmailSubscribed,
            isPhoneSubscribed,
            level,
            authorized: !!client,
          });

          localStorage.setItem("currentStep", "registrated");
        }
      } catch ({ response }) {
        if (response.status >= 400) {
          commit("SET_FAILED_ACTION", {
            action: "GET_BRAND",
            payload: { formCode, __gebugImg, __debugColor },
            status: response.status,
          });
        }

        if (response && response.data.error) {
          commit("SET_BRAND_STATUS", {
            status: "fail",
            error: errorMap[response.data.error.code],
          });
        }
      }
    },

    async SEND_CODE({ commit, state }, { form, getToken }) {
      try {
        commit("SET_FORM_SEND_CODE_STATUS", { status: "loading" });

        const token = isProd ? await getToken() : "dev-token";
        const { data = {} } = await app.post(
          `send-code`,
          {
            phoneNumber: form.phoneNumber,
            agreedWithTerms: state.agreedWithTerms,
          },
          { headers: { "X-ReCaptcha-Response": token } }
        );

        commit("SET_CLIENT", { tel: form.phoneNumber });
        commit("SET_FORM_SEND_CODE_STATUS", {
          status: "success",
          channel: data.channel || "",
        });
      } catch ({ response }) {
        if (response.status >= 500) {
          commit("SET_FAILED_ACTION", {
            action: "SEND_CODE",
            payload: form,
            status: response.status,
          });
        }

        if (response && response.data.error) {
          commit("SET_FORM_SEND_CODE_STATUS", {
            status: "fail",
            error: errorMap[response.data.error.code],
          });
        }
      }
    },

    async SEND_NEW_CODE({ commit, state }, { getToken }) {
      try {
        commit("SET_FORM_SEND_CODE_STATUS", { status: "loading" });

        const token = isProd ? await getToken() : "dev-token";

        const { data = {} } = await app.post(
          `send-code`,
          {
            phoneNumber: state.client.tel,
            agreedWithTerms: state.agreedWithTerms,
          },
          {
            headers: { "X-ReCaptcha-Response": token },
          }
        );

        commit("SET_FORM_SEND_CODE_STATUS", {
          status: "success",
          channel: data.channel || "",
        });
      } catch ({ response }) {
        if (response.status >= 500) {
          commit("SET_FAILED_ACTION", {
            action: "SEND_NEW_CODE",
            payload: {},
            status: response.status,
          });
        }
        if (response && response.data.error) {
          commit("SET_FORM_SEND_CODE_STATUS", {
            status: "fail",
            error: errorMap[response.data.error.code],
          });
        }
      }
    },

    async SUBMIT_PHONE({ commit, state }, code) {
      try {
        commit("SET_FORM_SUBMIT_PHONE_STATUS", { status: "loading" });

        const { data = {} } = await app.post(`submit-phone`, {
          phoneNumber: state.client.tel,
          code,
        });
        const { client } = data;
        const {
          walletsLink,
          isEmailSubscribed,
          isPhoneSubscribed,
          level,
          children,
          fields,
        } = client || {};

        if (children) {
          const prepateChildren = children.map((item) => ({
            text: item.name,
            date: item.birthdate,
            disabled: item.disabled || !!item.name,
          }));
          commit("SET_CLIENT_FIELDS", { children: prepateChildren, ...fields });
        } else {
          commit("SET_CLIENT_FIELDS", { ...fields });
        }

        commit("SET_FORM_SUBMIT_PHONE_STATUS", { status: "success" });
        commit("SET_CLIENT", {
          walletsLink,
          isEmailSubscribed,
          isPhoneSubscribed,
          level,
          authorized: !!client,
        });
      } catch ({ response }) {
        if (response && response.status >= 500) {
          commit("SET_FAILED_ACTION", {
            action: "SUBMIT_PHONE",
            payload: code,
            status: response.status,
          });
        }

        if (response && response.data.error) {
          commit("SET_FORM_SUBMIT_PHONE_STATUS", {
            status: "fail",
            error: errorMap[response.data.error.code],
          });
        } else {
          commit("SET_FORM_SUBMIT_PHONE_STATUS", {
            status: "fail",
          });
        }
      }
    },
    async SUBMIT_CLIENT({ commit }, data) {
      try {
        commit("SET_FORM_SUBMIT_CLIENT_STATUS", { status: "loading" });

        const {
          data: { client },
        } = await app.post(`submit-client`, {
          form: data,
        });

        commit("SET_FORM_SUBMIT_CLIENT_STATUS", { status: "success" });
        commit("SET_CLIENT", {
          walletsLink: client && client.walletsLink,
        });
      } catch ({ response }) {
        if (response.status >= 500) {
          commit("SET_FAILED_ACTION", {
            action: "SUBMIT_CLIENT",
            payload: data,
            status: response.status,
          });
        }

        if (response && response.data.error) {
          commit("SET_FORM_SUBMIT_CLIENT_STATUS", {
            status: "fail",
            error: errorMap[response.data.error.code],
            validationErrors:
              response.data.validationErrors &&
              response.data.validationErrors.map((error) => ({
                ...error,
                errorMessage: errorMap[error.errorCode],
              })),
          });
        }
      }
    },
    async GET_OPTIONS() {
      try {
        // const { data } = await app.get(`options`, {
        //   params: {
        //     field
        //   }
        // });
        // commit("SET_OPTION", {
        //   field,
        //   options: data
        // });
      } catch ({ response }) {
        return response;
      }
    },
  },
});
