<template>
  <Layout :fullHeight="true">
    <InnerPage center>
      <div class="request-error">
        <div class="request-error__img">
          <div class="request-error__icon">
            <IconError500 />
          </div>
          <div class="request-error__bg" />
        </div>
        <div class="request-error__text">
          <span v-if="code" class="request-error__code">{{ code }}</span>
          <span v-if="is400">{{ 'Вы перешли по неверной ссылке' }}</span>
          <span v-if="is500">{{ 'Не удалось отправить\nзапрос на сервер' }}</span>
        </div>
        <div v-if="is500" class="request-error__back">
          <Button @click="handleClick">ПОВТОРИТЬ ЗАПРОС</Button>
        </div>
      </div>
    </InnerPage>
  </Layout>
</template>

<script>
import { mapState } from "vuex";

import Layout from "@/components/Layout.vue";
import InnerPage from "@/components/InnerPage.vue";
import Button from "@/components/ui/Button.vue";
import IconError500 from "@/icons/IconError500.vue";

export default {
  name: "ErrorPage",
  props: { code: String },
  components: {
    Layout,
    InnerPage,
    IconError500,
    Button
  },
  computed: {
    ...mapState({
      failedAction: state => state.failedAction
    }),
    status() {
      if (this.failedAction) {
        return this.failedAction.status;
      }

      return 500;
    },
    is400() {
      return this.status >= 400 && this.status < 500;
    },
    is500() {
      return this.status >= 500;
    }
  },
  created() {
    if (!this.failedAction.action) {
      this.$router.push("/" + this.$route.params.brand);
    }
  },
  methods: {
    handleClick() {
      const { action, payload } = this.failedAction;
      this.$store.dispatch(action, payload);
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.request-error {
  width: 100%;
  position: relative;
  margin-bottom: 125px;

  @media (min-width: $screen-md) {
    position: static;
    margin-bottom: 0;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(4);
    background: url("data:image/svg+xml,%3Csvg data-v-c1f1971a='' xmlns='http://www.w3.org/2000/svg' width='687' height='687' viewBox='0 0 687 687' data-v-005bfc5a=''%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='345' cy='343.5' r='192.375' stroke='%233F35E2' stroke-width='.75' opacity='.159'%3E%3C/circle%3E%3Ccircle cx='342.75' cy='342.75' r='266.625' stroke='%233F35E2' stroke-width='.75' opacity='.147'%3E%3C/circle%3E%3Ccircle cx='343.5' cy='343.5' r='342.375' stroke='%233F35E2' stroke-width='.75' opacity='.092'%3E%3C/circle%3E%3Ccircle cx='342.75' cy='342.75' r='144' opacity='0' fill='%23DFE4F2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E")
      no-repeat center / contain;
    z-index: 1;

    @media (min-width: $screen-md) {
      transform: translate(-50%, -50%) scale(0.75);
      top: 50%;
      left: 50%;
      height: 686px;
      width: 686px;
    }
  }

  &__icon {
    position: relative;
    z-index: 2;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__text {
    margin-top: 32px;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    line-height: 1.33;
    text-align: center;

    @media (min-width: $screen-md) {
      position: absolute;
      top: 0;
      right: 50%;
      font-size: 42px;
      margin-top: 58px;
      transform: translateX(50%);
    }
  }

  &__code {
    font-size: 42px;
    @media (min-width: $screen-md) {
      font-size: 50px;
    }
  }

  &__back {
    width: 325px;
    margin: 0 auto 100px;
    position: absolute;
    bottom: -225px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    @media (min-width: $screen-md) {
      bottom: 100px;
      margin-bottom: 0;
    }
  }
}
</style>
