import Vue from 'vue';

import { validateDate } from './validateDate';

export const validateChildren = (field) => {
  if (field.code === 'children' && field.value) {
    field.value.forEach((child) => {
      if (child.text || child.date) {
        if (!child.text) {
          Vue.set(child, 'textError', true);
          field.error = true;
        }

        if (!validateDate(child.date)) {
          Vue.set(child, 'dateError', true);
          field.error = true;
        }
      }
    })
  }
}
