<template>
  <div class="spinner">
    <loader
      :active="true"
      color="#3366ff"
      loader="dots"
      :width="64"
      :height="64"
    />
  </div>
</template>
<script>
export default {
  name: "Spinner"
};
</script>

<style scoped lang="scss">
@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.spinner {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  animation: fade 0.25s ease-in-out 0.2s forwards;
}
</style>
