<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 24L5.54642 18.3506C4.59217 16.6969 4.09075 14.8223 4.09167 12.9001C4.09442 6.89042 8.98483 2 14.9936 2C17.9095 2.00092 20.6467 3.13667 22.7055 5.19733C24.7634 7.258 25.8964 9.997 25.8955 12.9102C25.8927 18.9207 21.0023 23.8112 14.9936 23.8112C13.1694 23.8102 11.3718 23.3528 9.77958 22.4838L4 24ZM20.4853 15.5016C20.4175 15.3879 20.236 15.3201 19.9628 15.1835C19.6906 15.0469 18.3513 14.3878 18.1011 14.2971C17.8517 14.2063 17.6702 14.1605 17.4878 14.4337C17.3063 14.7059 16.7838 15.3201 16.6252 15.5016C16.4667 15.6831 16.3072 15.706 16.0349 15.5694C15.7627 15.4328 14.8845 15.1459 13.8441 14.2173C13.0347 13.495 12.4874 12.6031 12.3288 12.3299C12.1702 12.0577 12.3123 11.9101 12.448 11.7744C12.5708 11.6525 12.7202 11.4563 12.8568 11.2968C12.9952 11.1392 13.0402 11.0255 13.1318 10.8431C13.2226 10.6616 13.1777 10.5021 13.1089 10.3655C13.0402 10.2298 12.4957 8.88875 12.2692 8.34333C12.0474 7.81258 11.8228 7.88408 11.656 7.87583L11.1335 7.86667C10.952 7.86667 10.6568 7.9345 10.4075 8.20767C10.1582 8.48083 9.45417 9.139 9.45417 10.4801C9.45417 11.8212 10.4304 13.1164 10.5661 13.2979C10.7027 13.4794 12.4865 16.2313 15.2191 17.411C15.869 17.6915 16.3768 17.8592 16.7719 17.9848C17.4246 18.192 18.0186 18.1627 18.4879 18.093C19.0113 18.0151 20.0994 17.4339 20.3267 16.7977C20.5541 16.1607 20.5541 15.6152 20.4853 15.5016Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconWhatsapp",
};
</script>
