<template>
  <div class="code" :class="{ error }">
    <div class="code__input">
      <Input
        :error="error"
        :disabled="disabled"
        ref="input-0"
        :value="getCode[0]"
        pattern="[0-9]*"
        inputmode="numeric"
        center
        @input="value => handleInput(0, value)"
      />
    </div>
    <div class="code__input">
      <Input
        :error="error"
        :disabled="disabled"
        ref="input-1"
        :value="getCode[1]"
        pattern="[0-9]*"
        inputmode="numeric"
        center
        @input="value => handleInput(1, value)"
      />
    </div>
    <div class="code__input">
      <Input
        :error="error"
        :disabled="disabled"
        ref="input-2"
        :value="getCode[2]"
        pattern="[0-9]*"
        inputmode="numeric"
        center
        @input="value => handleInput(2, value)"
      />
    </div>
  </div>
</template>

<script>
import Input from "@/components/ui/Input.vue";

export default {
  name: "Code",
  components: { Input },
  props: {
    error: Boolean,
    disabled: Boolean,
    code: Array
  },
  data: () => ({
    stateCode: ["", "", ""]
  }),
  computed: {
    getCode() {
      return this.stateCode;
    }
  },
  mounted() {
    this.$refs["input-0"].$el.querySelector("input").focus();
  },
  watch: {
    code: function(code) {
      this.stateCode = code;
    }
  },
  methods: {
    handleInput(index, value) {
      if (!/^\d+$/.test(value)) {
        this.$forceUpdate();
        return;
      }

      this.stateCode[index] = (value[0] !== this.stateCode[index]
        ? value[0]
        : value[1]
      ).trim();

      const code = this.stateCode.join("");
      const currentInput = this.$refs[`input-${index}`];
      const nextInput = this.$refs[`input-${index + 1}`];

      if (nextInput) {
        nextInput.$el.querySelector("input").focus();
      } else {
        currentInput.$el.querySelector("input").blur();
      }

      this.$emit("changeCode", code);
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
.code {
  display: flex;

  &__input {
    max-width: 70px;
    text-align: center;

    & + & {
      margin-left: 10px;
    }
  }
  &.error {
    input {
      color: $color-error;
    }
  }
}
</style>
