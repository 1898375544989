<template>
  <div class="autocomplete">
    <vue-simple-suggest
      :class="{ opened }"
      :name="name"
      :value="value"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @show-list="() => opened = true"
      @hide-list="() => opened = false"
      value-attribute="value"
      display-attribute="label"
      :list="getSuggestionList"
      :debounce="300"
      :filter-by-query="false"
    >
      <div class="input" :class="{ disabled }">
        <label
          v-if="label || typeof error === 'string'"
          class="input__label"
          :class="{ required: required && !error, error }"
        >
          {{ error || label }}
        </label>

        <div class="input__wrapper" :class="{ error, focus: isFocus }">
          <div class="input__part">
            <input
              class="input__control"
              :class="{ disabled }"
              :value="value"
              :placeholder="placeholder"
              :required="required"
              :disabled="disabled"
              autocomplete="off"
              :name="name"
            />
          </div>
          <GiftBall
            v-if="giftBall && !disabled"
            :value="giftBall"
            class="input__part input__part_gift"
          />
        </div>
      </div>
    </vue-simple-suggest>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest'
import GiftBall from "@/components/ui/GiftBall.vue";

export default {
  name: 'Autocomplete',
  components: {
    VueSimpleSuggest,
    GiftBall,
  },
  props: {
    label: String,
    name: String,
    placeholder: String,
    required: Boolean,
    disabled: Boolean,
    error: [String, Boolean],
    giftBall: Number,
    value: String,
    getSuggestionList: Function,
  },
  data() {
    return {
      isFocus: false,
      opened: false,
    };
  },
  methods: {
    handleFocus(e) {
      this.isFocus = true;
      this.$emit('focus', e);
    },

    handleBlur(e) {
      this.isFocus = false;
      this.$emit('blur', e);
    },

    handleInput(value) {
      this.$emit('input', value);
    },
  }
}
</script>

<style lang="scss">
  .autocomplete {
    .vue-simple-suggest {
      position: relative;

      &.opened.focus {
        .input__wrapper,
        .input__control,
        .input__gift {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none;
        }
      }
    }

    .suggestions {
      position: absolute;
      left: 0;
      right: 0;

      list-style: none;
      margin: 0;
      padding: 0;

      background: $color-input-bg;
      border: 1px solid $color-input-border-active;
      border-top: none;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;

      z-index: 1000;

      @media (min-width: $screen-md) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .suggest-item {
       padding: 10px 20px;
       cursor: pointer;
       font-family: "Muller", Helvetica, Arial, sans-serif;
       font-size: 20px;
       font-weight: normal;
       color: #2e2e2e;
       overflow: hidden;
       text-overflow: ellipsis;

       &.hover {
         color: #56699f;
       }
    }
  }
</style>
