<template>
  <div>
    <div class="field">
      <TelInput
        :value="value"
        :error="telFieldError"
        disabled
        @onClickChange="handleClickChange"
        withChange
      />
    </div>
    <ClientForm
      :fields="fields"
      @submit="handleSubmit"
      :error="form.clientSubmit.error"
      :validationErrors="form.clientSubmit.validationErrors"
      :loading="loading"
    />
  </div>
</template>

<script>
import TelInput from "@/components/TelInput.vue";
import ClientForm from "@/components/ClientForm.vue";
import { mapState } from "vuex";

export default {
  name: "RegistrationForm",
  components: {
    ClientForm,
    TelInput
  },
  data: () => ({
    loading: false
  }),
  beforeCreate() {
    this.$store.commit("SET_FORM_SUBMIT_CLIENT_STATUS", {});
    localStorage.setItem("currentStep", "registrated");
  },
  computed: {
    ...mapState({
      fields: state => state.fields,
      form: state => state.form,
      phoneMask: state => state.phoneMask,
      telFieldError: state => {
        const telField = (state.form.clientSubmit.validationErrors || []).find(
          field => field.code === "phoneNumber"
        );
        if (!telField) {
          return;
        }

        return telField.errorMessage;
      }
    }),
    value() {
        return this.$store.state.client.tel;
    },
  },
  methods: {
    handleClickChange() {
      this.$store.commit("SET_CLIENT", { tel: null });
      this.$emit("cancel");
    },

    handleSubmit(form) {
      this.loading = true;
      this.$store.dispatch("SUBMIT_CLIENT", form).then(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.form.clientSubmit.status === "success" && this.$emit("submit");
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.field {
  margin-bottom: 20px;
}
</style>
