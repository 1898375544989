var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.href)?_c('button',_vm._b({staticClass:"button",class:{
    button_disabled: _vm.disabled,
    button_telegram: _vm.isTelegram,
    button_phone: _vm.isPhone,
    button_sms: _vm.isSMS,
    button_whatsapp: _vm.isWhatsapp,
  },on:{"click":function($event){return _vm.$emit('click')}}},'button',_vm.$attrs,false),[_c('div',{staticClass:"content"},[_vm._t("default"),(_vm.isTelegram)?_c('IconTelegram'):_vm._e(),(_vm.isSMS)?_c('IconSMS'):_vm._e(),(_vm.isPhone)?_c('IconPhone'):_vm._e()],2)]):_c('a',{staticClass:"button",class:{
    button_disabled: _vm.disabled,
    button_telegram: _vm.isTelegram,
    button_phone: _vm.isPhone,
    button_sms: _vm.isSMS,
    button_whatsapp: _vm.isWhatsapp,
  },attrs:{"href":_vm.href}},[_c('div',{staticClass:"content"},[_vm._t("default"),(_vm.isTelegram)?_c('IconTelegram'):_vm._e(),(_vm.isSMS)?_c('IconSMS'):_vm._e(),(_vm.isPhone)?_c('IconPhone'):_vm._e(),(_vm.isWhatsapp)?_c('IconWhatsapp'):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }