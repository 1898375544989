<template>
  <div class="inner" :class="{ center }">
    <h1 class="inner__title" :class="{ center }">{{ title }}</h1>
    <div v-if="subTitle" class="inner__subtitle" :class="{ center }">
      {{ subTitle }}
    </div>
    <div
      v-if="mobileSubTitle"
      class="inner__mobile-subtitle"
      :class="{ center }"
    >
      {{ mobileSubTitle }}
    </div>
    <div class="inner__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "InnerPage",
  props: {
    title: String,
    subTitle: String,
    mobileSubTitle: String,
    center: Boolean,
  },
};
</script>

<style scoped lang="scss">
.inner {
  padding: 0 24px 24px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  @media (min-width: $screen-md) {
    min-height: 700px;
    padding: 60px 68px;
  }

  &__title {
    @extend %title;
    margin: 0;
    position: relative;
    z-index: 2;
    max-width: 590px;
    text-align: center;

    &.center {
      text-align: center;
      max-width: none;
    }

    @media (min-width: $screen-md) {
      text-align: left;
      &.center {
        text-align: center;
        max-width: none;
      }
    }
  }

  &__content {
    margin-top: 52px;

    @media (max-width: $screen-md) {
      margin-top: 0;
    }
  }

  &.center {
    .inner__content {
      margin: auto 0;
    }
  }

  &__mobile-subtitle,
  &__subtitle {
    @extend %subTitle;
    margin: 6px auto 0;
    text-align: center;
    white-space: pre-wrap;
  }

  &__mobile-subtitle {
    @extend %onlyMobile;
  }
}
</style>
