<template>
  <div class="layout-card">
    <div class="layout-card__preview">
      <Card
        text="123456"
        :img="$store.state.brand.logo"
        :bg-color="$store.state.brand.cardColor"
      />
    </div>
    <div class="layout-card__bg" />
  </div>
</template>

<script>
import Card from "@/components/Card.vue";

export default {
  name: "LayoutCard",
  components: {
    Card
  }
};
</script>

<style lang="scss" scoped>
.layout-card {
  width: 100%;
  position: relative;

  @media (min-width: $screen-md) {
    position: static;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(3);
    background: url("data:image/svg+xml,%3Csvg data-v-c1f1971a='' xmlns='http://www.w3.org/2000/svg' width='687' height='687' viewBox='0 0 687 687' data-v-005bfc5a=''%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='345' cy='343.5' r='192.375' stroke='%233F35E2' stroke-width='.75' opacity='.159'%3E%3C/circle%3E%3Ccircle cx='342.75' cy='342.75' r='266.625' stroke='%233F35E2' stroke-width='.75' opacity='.147'%3E%3C/circle%3E%3Ccircle cx='343.5' cy='343.5' r='342.375' stroke='%233F35E2' stroke-width='.75' opacity='.092'%3E%3C/circle%3E%3Ccircle cx='342.75' cy='342.75' r='144' fill='%23DFE4F2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E")
      no-repeat center / contain;

    @media (min-width: $screen-md) {
      transform: translate(-50%, -50%) scale(1);
      top: 50%;
      left: 50%;
      height: 686px;
      width: 686px;
    }
  }

  &__preview {
    width: 100%;
    z-index: 2;

    position: relative;
    @media (min-width: $screen-md) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__bg {
    z-index: 1;
  }
}
</style>
