<template>
  <div>
    <TelInput
      :value="$store.state.client.tel"
      disabled
      @onClickChange="handleClickChange"
      withChange
    />
    <div class="sms">
      <SmsCode @confirm="handleConfirm" />
    </div>
  </div>
</template>

<script>
import TelInput from "@/components/TelInput.vue";
import SmsCode from "@/components/SmsCode.vue";

export default {
  name: "SubmitPhoneForm",
  components: {
    TelInput,
    SmsCode
  },
  beforeCreate() {
    localStorage.setItem("currentStep", "smsCode");
  },
  methods: {
    handleClickChange() {
      this.$store.commit("SET_CLIENT", { tel: null });
      this.$emit("cancel");
    },

    handleConfirm() {
      this.$emit("confirm");
    }
  }
};
</script>

<style lang="scss" scoped>
.sms {
  margin-top: 38px;
}
</style>
