<template>
  <Input
    :placeholder="getPlaceholder()"
    type="tel"
    label="Мобильный телефон"
    :mask="phoneMask"
    :hint-placeholder="!phoneNumberVerified"
    v-bind="$attrs"
    v-bind:value="getValue()"
    @input="handleInput"
    @onBlur="$emit('onBlur', $event)"
    pattern="[0-9]*"
    inputmode="numeric"
    @onClickChange="$emit('onClickChange')"
    v-bind:autofocus="autofocus"
  />
</template>

<script>
import { mapState } from "vuex";
import Input from "@/components/ui/Input.vue";

export default {
  name: "TelInput",
  props: { value: String, autofocus: Boolean },
  components: {
    Input
  },
  computed: {
    ...mapState({
      brand: state => state.brand,
      phoneMask: state => state.phoneMask,
      initValue: state => state.phoneMask.match('^[^#]+')[0]
    }),
    phoneNumberVerified() {
      return this.brand.phoneNumberVerified;
    }
  },
  watch: {
    "$store.state.phoneMask": {
      handler() {
        this.$nextTick(() => {
          this.handleInput("");
        });
      },
      immediate: true,
    }
  },
  methods: {
    handleInput(value = "") {
      this.$emit("input", value);
      if (!value.startsWith(this.initValue)) {
        this.$emit("input", this.initValue);
      }
    },
    getPlaceholder() {
      return this.phoneMask.replace(/[#]/g, "0");
    },
    getValue() {
      return this.value || this.initValue;
    }
  }
};
</script>
