<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.20898 19.6221L20.2905 6.5406" stroke="currentColor" stroke-width="3"/>
    <path d="M7.20898 6.54004L20.2905 19.6215" stroke="currentColor" stroke-width="3"/>
  </svg>
</template>

<script>
export default {
  name: "IconX"
};
</script>
