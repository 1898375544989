import { render, staticRenderFns } from "./SubmitPhoneForm.vue?vue&type=template&id=719fdd84&scoped=true"
import script from "./SubmitPhoneForm.vue?vue&type=script&lang=js"
export * from "./SubmitPhoneForm.vue?vue&type=script&lang=js"
import style0 from "./SubmitPhoneForm.vue?vue&type=style&index=0&id=719fdd84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "719fdd84",
  null
  
)

export default component.exports