const URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest';

const makeRequest = (url, body, apiKey) =>
  fetch(`${URL}/${url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${apiKey}`,
    },
    body: JSON.stringify(body),
  })
  .then((response) => response.json())
  .then((data) => {
    const { suggestions } = data;

    const map = {};
    const filteredSuggestions = suggestions.filter((s) => {
      return map.hasOwnProperty(s.value) ? false : (map[s.value] = true);
    })

    return { ...data, suggestions: filteredSuggestions };
  });

export const fetchSuggestions = (apiKey, type, query) => {
  switch(type) {
    case 'fullName': {
      return makeRequest('fio', { query }, apiKey);
    }

    case 'name': {
      return makeRequest('fio', { query, parts: ['NAME'] }, apiKey);
    }

    case 'surname': {
      return makeRequest('fio', { query, parts: ['SURNAME'] }, apiKey);
    }

    case 'patronymic': {
      return makeRequest('fio', { query, parts: ['PATRONYMIC'] }, apiKey);
    }

    case 'email': {
      return makeRequest('email', { query }, apiKey);
    }
  }

  return Promise.resolve({ suggestions: [] });
}
