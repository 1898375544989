<template>
  <div class="smscode" :class="{ error }">
    <div class="smscode__label">{{ error ? error : label }}</div>
    <div class="smscode__control">
      <Code
        :error="!!error"
        @changeCode="handleChangeCode"
        :code="code"
        :disabled="disabled"
      />
    </div>
    <div class="smscode__timer">
      <Timer :time="time" text="Запросить новый код можно через">
        <template slot="reset">
          <div class="smscode__timeout link" @click="handleSendNewCode">
            Запросить новый код
          </div>
        </template>
      </Timer>
    </div>

    <div class="smscode__help" v-if="verifyByTelegram || verifyByWhatsapp">
      <div>Не приходит код?</div>

      <div class="social">
        <span> Попробуйте через</span>
        <a
          v-if="verifyByTelegram"
          class="link social__icon"
          href="/verify-phone?by=telegram"
          ><IconTelegram /> Telegram
        </a>

        <template v-if="verifyByWhatsapp">
          <span v-if="verifyByTelegram">или</span>
          <a class="link social__icon" href="/verify-phone?by=whatsapp">
            <IconWhatsapp /> WhatsApp
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Code from "@/components/Code.vue";
import Timer from "@/components/Timer.vue";
import IconTelegram from "@/icons/IconTelegram.vue";
import IconWhatsapp from "@/icons/IconWhatsapp.vue";

export default {
  name: "SmsCode",
  components: {
    Code,
    Timer,
    IconTelegram,
    IconWhatsapp,
  },
  data: () => ({
    code: null,
    showError: false,
    stateError: "",
    disabled: false,
  }),
  computed: {
    ...mapState({
      form: (state) => state.form,
      brand: (state) => state.brand,
    }),
    channel() {
      return this.form.sendCode.channel || this.brand.codeAdapter;
    },
    time() {
      return this.channel === "flash_call" ? 45 : 60;
    },
    label() {
      if (this.channel === "flash_call") {
        return "Последние цифры номера";
      }

      return "SMS-код";
    },
    verifyByTelegram() {
      return this.brand.verifyMethods.includes("telegram");
    },

    verifyByWhatsapp() {
      return this.brand.verifyMethods.includes("whatsapp");
    },

    error() {
      return this.showError && this.stateError;
    },
  },
  methods: {
    handleChangeCode(code) {
      this.showError = false;

      if (code.length === 3) {
        this.disabled = true;
        this.$store.dispatch("SUBMIT_PHONE", code).then(() => {
          this.disabled = false;
          const { status, error } = this.$store.state.form.submitPhone;
          if (status === "fail") {
            this.stateError = error;
            this.code = ["", "", ""];
            this.showError = true;
          } else {
            this.showError = false;
            this.$emit("confirm");
          }
        });
      }
    },

    getTokenSendCode() {
      return this.$recaptcha("send_code");
    },

    handleSendNewCode() {
      this.code = ["", "", ""];
      this.showError = false;
      this.$store.dispatch("SEND_NEW_CODE", {
        getToken: this.getTokenSendCode,
      });
    },
  },
};
</script>

<style lang="scss">
.smscode {
  color: $color-text-pseudo;

  &__label,
  &__help {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;

    @media (min-width: $screen-md) {
      font-size: 16px;
      font-weight: normal;
    }
  }

  &__timer,
  &__help {
    margin-top: 16px;
    @media (min-width: $screen-md) {
      margin-top: 37px;
    }
  }

  &__timeout {
    @include click-element();
  }

  &__help {
    color: $color-text-pseudo;
  }

  &.error {
    color: $color-error;
  }
}

.social {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-weight: 400;
  flex-wrap: wrap;

  span {
    flex-shrink: 0;
  }

  &__icon {
    display: flex;
    align-items: center;
    column-gap: 6px;

    svg {
      width: 28px;
      height: 28px;
    }

    path {
      fill: #3366ff;
    }
  }

  @media (min-width: $screen-md) {
    flex-wrap: nowrap;
  }
}
</style>
