<template>
  <ErrorPage v-if="$route.hash === '#error'" />
  <ThankyouPage v-else-if="$route.hash === '#thankyou'" />
  <QRCodePage v-else-if="$route.hash === '#qr'" />
  <Main v-else />
</template>

<script>
import { mapState } from "vuex";

import Main from "@/views/Main.vue";
import ThankyouPage from "@/views/ThankyouPage.vue";
import QRCodePage from "@/views/QRCodePage.vue";
import ErrorPage from "@/views/ErrorPage.vue";

export default {
  name: "Index",
  components: { Main, ThankyouPage, QRCodePage, ErrorPage },
  computed: {
    ...mapState({
      failedAction: state => state.failedAction
    })
  },
  watch: {
    failedAction() {
      if (!this.failedAction.action) {
        return;
      }

      this.$router.push({ path: this.$route.path + "#error" });
    }
  }
};
</script>
