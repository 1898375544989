import { render, staticRenderFns } from "./ChildrenInput.vue?vue&type=template&id=366751e0&scoped=true"
import script from "./ChildrenInput.vue?vue&type=script&lang=js"
export * from "./ChildrenInput.vue?vue&type=script&lang=js"
import style0 from "./ChildrenInput.vue?vue&type=style&index=0&id=366751e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "366751e0",
  null
  
)

export default component.exports