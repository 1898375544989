<template>
  <div class="dropdown" :class="{ error }">
    <label
      v-if="label || typeof error === 'string'"
      class="dropdown__label"
      :class="{ required: required && !error, error }"
    >{{ error || label }}</label>
    <v-select
      append-to-body
      :calculate-position="withPopper"
      :clearable="false"
      class="dropdown__control"
      :options="options"
      index="value"
      label="label"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @search:blur="$emit('onBlur', $event)"
      v-bind="$attrs"
      v-bind:value="getValue()"
    >
      <span slot="no-options">Ничего не найдено</span>
    </v-select>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "Dropdown",
  props: {
    error: [String, Boolean],
    label: String,
    required: Boolean,
    value: [Object, String],
    options: [Array, null]
  },
  data: () => ({ placement: "bottom" }),
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1]
            }
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.setAttribute("placement", state.placement);
            }
          }
        ]
      });
      return () => popper.destroy();
    },
    getValue() {
      const { options, value } = this;
      return options.find(option => option.value === value) || value;
    }
  }
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";

.dropdown {
  &__label {
    line-height: 1.35;
    color: $color-text-pseudo;
    margin-bottom: 6px;
    display: inline-block;

    font-size: 14px;
    font-weight: 500;

    @media (min-width: $screen-md) {
      font-size: 16px;
      font-weight: normal;
    }

    &.error,
    &.required::after {
      content: "*";
    }

    &.error {
      color: $color-error;
    }
  }

  &.error .vs__dropdown-toggle {
    border-color: $color-error;
  }
}

.vs__dropdown-toggle {
  @extend %controlsFontSize;
  border-radius: 15px;
  background-color: $color-input-bg;
  color: $color-input-text;
  width: 100%;
  border: solid thin transparent;
  transition: 0.15s ease-in-out;
  position: relative;
  z-index: 3;
  display: flex;
  overflow: hidden;
  min-height: 53px;
  padding: 11px 20px;
  box-sizing: border-box;

  @media (min-width: $screen-md) {
    min-height: 70px;
    padding: 16px 20px;
    border-radius: 10px;
  }

  .vs__actions,
  .vs__search,
  .vs__search:focus,
  .vs__selected {
    margin: 0;
    padding: 0;
  }

  .vs__search {
    color: $color-text-pseudo;
    -webkit-appearance: textfield;
  }

  .vs__actions {
    cursor: pointer;
  }
}

.vs__selected-options {
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vs__selected {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.vs__search,
.vs__search:focus,
.vs__dropdown-option,
.vs__selected {
  @extend %controlsFontSize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: $color-input-text;
}

.vs__dropdown-option {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  font-family: "Muller", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: normal;
  color: #2e2e2e;
}

.vs__dropdown-menu {
  position: relative;
  background: $color-input-bg;
  border: 0;
  box-shadow: none;
  max-height: 200px;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  @media (min-width: $screen-md) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &[data-popper-placement="top"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    @media (min-width: $screen-md) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}

.vs--open {
  &[placement="top"] .vs__dropdown-toggle {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    @media (min-width: $screen-md) {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &[placement="bottom"] .vs__dropdown-toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    @media (min-width: $screen-md) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}

.vs__no-options {
  font-family: "Muller", Helvetica, Arial, sans-serif;
}

.vs__dropdown-option--highlight {
  background: $color-input-bg;
  color: #56699f;
}
.vs--disabled {
  filter: grayscale(1);
}

.vs--disabled .vs__search,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator {
  background-color: #f1f1f1;
}

.vs--disabled .vs__selected {
  color: #9299a2;
}
</style>
