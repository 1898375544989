import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import device from "vue-device-detector";
import VueMask from "v-mask";
import vSelect from "vue-select";
import IconArrowDropdown from "@/icons/IconArrowDropdown";
import Loading from "vue-loading-overlay";
import VCalendar from "v-calendar";
import { VueReCaptcha } from "vue-recaptcha-v3";

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: (createElement) => createElement(IconArrowDropdown),
  },
});

Vue.use(VueMask);
Vue.use(device);
Vue.directive("mask", VueMask.VueMaskDirective);
Vue.component("v-select", vSelect);
Vue.component("loader", Loading);
Vue.use(VCalendar, {
  componentPrefix: "v",
});
Vue.use(VueReCaptcha, {
  siteKey: "6LdpKdgUAAAAANh2vsNOq4lgnnANIglcvlhpZbq9",
  loaderOptions: {
    autoHideBadge: true,
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    const { brand = "" } = this.$route.params;
    const brandName = localStorage.getItem("brandName");

    if (brand !== brandName) {
      localStorage.removeItem("brandName");
      localStorage.removeItem("brandLogo");
      localStorage.removeItem("brandCardColor");
      localStorage.removeItem("termsLink");
      localStorage.removeItem("phoneMask");
      localStorage.removeItem("codeAdapter");
      localStorage.removeItem("verifyTopMethod");
      localStorage.removeItem("verifyMethods");
      localStorage.removeItem("dadataApiKey");

      localStorage.removeItem("clientTel");
      localStorage.removeItem("clientWalletsLink");

      localStorage.removeItem("fields");
      localStorage.removeItem("currentStep");

      if (this.$route.path !== "/" + brand) {
        this.$router.push({ path: "/" + brand });
      }
    }
  },
}).$mount("#app");
